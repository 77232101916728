
body{
  background-color: #000; color:  #f2f2f2;
}

.container-bg{
  background-color: #000; color:  #f2f2f2;
}

.navbar{
  border-radius: 10px;
}

#img_profile{
  padding: 20px;
}

.img-fluid{
  scale:80%;  border-radius: 5%;
}

#HeaderComponent{ 
  padding-bottom: 30px; padding-top: 100px; color:  #ffad33;
}

#nav-bar{
  padding-bottom: 20px; color:  #ffad33;
}

#tech-skills ul.skills li .style2{ 
  padding-left: 10px; color:  #ffad33;
}
span.style2{
  padding-left: 0;
}
.style2{ 
  padding-left: 10px; color:  #ffad33; 
}
#work_places div{
  font-style: italic;
}

#tech-skills ul.skills>li{ 
  padding-left: 10px;
  padding-bottom: 20px;
}

ul.skills {
  list-style: none;
}

#tech-skills{
  padding-top: 100px;
}

#projects{
  padding-top: 100px;
}

#education{
  padding-top: 100px;
}

#summary{
  padding-top: 30px; padding-bottom: 0px;
}

#projects dl dt{
  color:rgb(156, 220, 250);
}

#contact-info li{
  list-style: none; padding-bottom: 20px;
}

#contact-info{
  padding-left: 0; padding-top: 20px;
}

#contact h4{
  padding-top: 0px; padding-bottom: 20px;
}

.App {
  text-align: center;
}